:root {
  --text-font: system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  --primary-bg-color: #ffffff;
  --secondary-bg-color: #162ec7;
  --primary-figma-color: #c41b78;
  --textOverPrimaryColor: #FFFFFF;
  --textDarkToneColor: #000000;
  --hamburgerAndSentButton: #a01b64e0;
  --navBarColor: #3AADA8;
  --navBar-Text-Color: #FFFFFF;
  --viewport-width: 100vw;
  --viewport-height: 100vh;
--BgColorForActiveTime:#6a1343;
--TextColorforActiveTime:#ffffff;


}

.activeButtonStyleForTouchScreens {
  transform: scale(1.1);
  background-color:var(--BgColorForActiveTime);
  color: var(--TextColorforActiveTime);
  font-weight: bold;
}

.App {
  font-family: var(--text-font);
  text-align: center;
  background-color: var(--primary-bg-color);
  color: #ffffff;
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.mainitem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.button-container1 {
  margin-right: 20px;
  /* Add space between buttons */
  display: flex;
  align-items: center;
}

#searchIcon{
  height: 4vh;
  width:4vh;
}
.header1_ECD{
  height: 9vw;
  background-color: var(--primary-bg-color);
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  margin-bottom: 1vh;
  display: flex;
  flex-direction: row;
  width: 99vw;
  justify-content: center;
  align-items: center;
}

@keyframes 
FromTop {
  from {
    transform: translateY(-200%);
  }
  to {
    transform: translateY(0);
  }

}


/*    2nd Main DIV      */
.inputbox_type_ECD {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 3vh;
  margin-left: 1vw;
    margin-right: 1vw;
    animation: FromTop 2s ease-out; 
    

}



.inputPromptWrapper_ECD{
  display: flex;
  flex-direction: row;
  align-items: center;

  width: 95vw;
  justify-content: space-between;
}


.inputPrompttTextarea_ECD{
  display: flex;
  flex-direction: column;
  height: 10vh;
  min-height: 6vh;
  border-radius: 0.75vw;
  justify-content: center;
  border-color:var(--inputBoxBorder);
  border-width: 1vh;
  background-color:var(--primary-bg-color);
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
}

.input_area_ECD{
  border: none;
  resize: none;
  margin: 1.1vw;
  min-width: 75vw;
  max-width: 75vw;
  min-height: 6vh;
  background-color: var(--primary-bg-color);

  }
  
  
.input_area_ECD:focus-visible{
  
    border:none;
    outline: none;
  }



  .sentbutton_ECD {

    border-radius: 1vh;
    border: none;
    width: 45px;
    height: 45px;
    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  
    transition: transform 0.3s ease-in-out;

  }
  
  

  
    .Query_Wrapper_ECD{
      width: 95vw;
     }



     .chatPromptMainContainer_ECD{
      display: flex;
      flex-direction: row;
      background-color: var(--primary-figma-color);
      border-radius: 1.1vw;
      margin-bottom: 1.5vh;
      margin-top: 1.5vh;
      justify-content: space-between;
      align-items: center;
      
      }
      
      .chatPromptWrapper_ECD{
        min-height: 6vh;
        min-width: 70vw;
        max-width: 80vw;
display: flex;
flex-direction: row;     


      }
      
      .chatPrompt_ECD{
        color: var(--primary-bg-color);
        padding: 1.5vh;
        font-weight: 800;
        font-size: medium;
        text-align: left;
        text-transform: capitalize;
        
        
      }
      
      .showRelatedButton_ECD{
        margin:1vh;
        background-color:var(--primary-bg-color);
        color: var(--primary-figma-color);
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
        font-size: small;
        font-weight: 400;
        text-align: right;
        min-width: 20vw;
        border-radius: 0.6vw;
        padding: 0.5vh;
        max-height: 6vh;
        min-height: 4vh;
        display: flex;
        align-items: center;
        padding-right: 1vw;
    padding-top: 0.5vh;
    padding-bottom: 0.5vh;
    transition: transform 0.3s ease-in-out;
  }
     

      .showRelatedButton_ECD:active{
        transform: scale(1.1);
        background-color:var(--BgColorForActiveTime);
        color: var(--TextColorforActiveTime);
        font-weight: bold;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6); 
      }


      @keyframes 
      slideIn {
        from {
          transform: translateX(-100%);
        }
        to {
          transform: translateX(0);
        }
      
        
      }
      
      
      @keyframes slideOut{
        from{
          transform: translateX(0);
        }
        to{
          transform:translate(-100%)
        
        }
      }
      
      
      .relatedSection_ECD{
        width: 95vw;
        background-color: var(--hamburgerAndSentButton);
        animation: slideIn 0.5s ease-out; 
        border-radius: 0.7vh;
      
      }
      

.relatedHeading_ECD{
  color:var(--primary-figma-color);
  background-color: var(--primary-bg-color);
  height:4vh;
  margin-top: 2.5vh;
  border-radius: 0.3vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  font-weight: 600;
 
}



/* Apply the animation to the element */
.relatedHeading_ECD {
  animation: slideIn 1s ease-out; /* Animation name, duration, and timing function */
}

.relatedHeading_ECD.hide{
  animation:  slideOut 1s ease-in
}

.relatedQuestionsWrapper_ECD{
  max-height: 20vh;
  margin-top: 1vh;
  overflow-y: auto;
  margin-left: 2vw;
}

.questions_ECD{
  background-color: #ffffff80;
  max-width: 90vw;
  margin-top: 1vh;
  min-height: 6vh;
  align-items: center;
  font-size: small;
  display: flex;
  color: #000000;
  text-align: left;
  border-radius: 0.6vh;
  transition: transform 0.3s ease-in-out;
}


.subjectName_ECD{
background: var(--primary-bg-color);
padding: 1vw;
font-size:10px;
margin-left: 10px;
border-radius: 5px;
text-align: right;
}

.questions_ECD:active{
  transform: scale(.9);
  background-color:var(--BgColorForActiveTime);
  color: var(--TextColorforActiveTime);
  font-weight: bold; 
}

.questionRelated_ECD{
  margin-left: 2vw;
}

.buttonRelatedWrapper_ECD{
  min-height: 6vh;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding: 1vh;
}

.relatedButton_ECD{
  background-color:var(--primary-bg-color);
  color: var(--primary-figma-color);
  display: flex;
  min-width: 30vw;
  align-items: center;
  justify-content: center;
  border-radius: 0.7vh;
  transition: transform 0.3s ease-in-out;
}

.relatedButton_ECD:active {
  transform: scale(1.1);
  background-color:var(--BgColorForActiveTime);
  color: var(--TextColorforActiveTime);
  font-weight: bold;
}



/* whatsapp Share for ECD */

.logo_wrapper_ECD {
  max-height: 6.5vh;
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

#dentalailogo_ECD {
  width: 50vw;
  height: 50vw;

}


@keyframes 
ZoomIn {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}

.avatar-logo2_ECD {
  width: auto;
  height: auto;
  animation: ZoomIn 1s ease-out; 
}

/* Keyframe animations */
@keyframes fadeInFromBottom {
  from {
    opacity: 0;
  
  }
  to {
    opacity: 1;

  }
}


.avatar-logo3_ECD{
color: #000000;
animation: fadeInFromBottom 4s ease-in; 

}
/*Animation for the image */










/* Landscape orientation */
@media screen and (orientation: landscape) {
 #dentalailogo_ECD {
    width: auto;
    height: 75vh; /* Adjust height as needed */
  }
}

/* Portrait orientation */
@media screen and (orientation: portrait) {
  #dentalailogo_ECD {
    width: 75vw; /* Adjust width as needed */
    height: auto;
  }
}


/* -------------*/



.feedbackWrapper{
  display: flex;
  flex-direction: column;
  width: 70vw;
  align-content: center;
  justify-content: center;
  align-items: center;
  padding: 1vh;
}


.feedbackcontainer{
  min-height: 13vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  justify-content: center;
  align-items: center; 
}

.feedback{
  color: var(--primary-bg-color);
  background: var(--primary-figma-color);
  border-radius: 1vh;
  max-width: 44vw;
  min-width: 44vw;
  display: flex;
  align-content: center;
  justify-content: center;
  align-items: center; 
  padding:1vh;
  font-weight: 600;
  text-align: center;
  box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease-in-out;
  animation: Frombottom 2s ease-out; 
  transform: scale(0.8);
}

@keyframes 
Frombottom {
  from {
    transform: translateY(200%);
    opacity: 0;
    scale: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
    scale: 0.8;
  }

}


.feedback:active{
  transform: scale(1.1);
  background-color:var(--BgColorForActiveTime);
  color: var(--TextColorforActiveTime);
  font-weight: bold;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6); 
}



.feebacklink {
  text-decoration: none; /* Remove underline */
  color:var(--primary-figma-color); /* Unvisited link color */
}

.feebacklink:visited {
  color:var(--primary-figma-color); /* Visited link color */
}

.header1 {
  width: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  position: relative;

}

.topHeadingInTop {
  display: flex;
  align-items: center;
}

header {
  display: none;
}

header h1 {
  font-weight: 400;
  right: 10%;
  font-size: 25px;
  line-height: 24px;
  color: var(--textOverPrimaryColor);
  margin: auto;
}

.menu button {
  background: none;
  border: none;
}

nav {
  display: none;

  opacity: 1;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 999;
  height: 100vh;
  max-width: 340px;
}

.photobackground {
  background: var(--primary-bg-color);
  margin-top: 40%;
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  border-radius: 10px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

}

.photo {
  border-radius: 10px;
  margin-bottom: 39px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.whatsappcontainer {
  /* Add space between buttons */
  display: flex;
  align-items: center;
}

.photocontainer {
  padding-top: 20px;
  padding-bottom: 20%;


}

.namecontainer {
  padding-top: 20px;
}

.nameribbon {
  background: var(--primary-figma-color);
  height: max-content;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.namealign {
  text-align: center;
  font-size: 16px;
  vertical-align: middle;
  padding-top: 8px;
  padding-bottom: 8px;
}

.stopmessage1 {
  background-color: #e5e4e4;
}

.navItems {
  opacity: 1;
  background-color: var(--navBarColor);
  width: 100%;
  max-width: 250px;

}

.navPromptWrapper {
  border-bottom: 1px solid #f5f0f0e8;
  height: calc(100vh - 330px);
  overflow-y: auto;
}






.avatar-wrapper {
  display: flex;
  justify-content: space-between;

  align-items: center;
  
  width: 100vw;
  height: 10vh;
  box-sizing: border-box;
}
.avatar-container1 {
  display: contents;
  justify-content: left;
  align-items: center;

}
.avatar-button2 {
  color: #000000;
  display: flex;
  /* Add space between buttons */
}

.avatar-logo2{
  width: 80px;
  justify-content: left;
}
#dentalailogo {
  width: 80px;
  height: 80px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: left;
border-radius: 50%;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  object-position: center;
}

.hitext {
  font-size: 14px;
  padding-top: 30px;
 font-weight: 300;
  text-align: left;
}

.hitext2 {
  font-size: 16px;
 font-weight: 600;
  text-align: left;
}



.right-side-container {
  display: flex;
  float: right;
  align-items: center;
  justify-content: center;
}
.avatar-button1 {
  margin-right: 20px;
  /* Add space between buttons */
}
#whatsapplogo {
  display: flex;
  padding: 5px;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: contain;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);

  object-position: center;
}

.avatar-logo1 {
  vertical-align: middle;
  /* Additional styling for the logo */
}
.navPrompt {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  height: 35px;
  width: 35px;
  border-radius: 50%;
  background-color: #ffffff;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  column-gap: 20px;
  margin: 5px 0;
  text-align: left;
}


.navlinks-container1 {
  display: flex;
  float: right;
  padding-right: 2px;  

  align-items: center;
  /* Align items vertically */
}

.navPrompt a {
  display: flex;

  align-items: center;
  column-gap: 20px;
  text-decoration: none;
}

.navPrompt p {
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: 150px;
  margin: 0;
}


.navPrompt span {
  animation: fadeInChar 1s ease-in forwards;
  opacity: 0;
}

#botMessage pre {
  margin: 0;
  padding-top: px;
  text-indent: 0;
  white-space: pre-wrap;
  position: relative;

}

pre {
  font-family: var(--text-font);
}

@keyframes fadeInChar {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.navCloseIcon {
  width: auto;
  border-radius: 20%;
  float: right;
  max-height: 40px;

  margin-top: 5%;
  margin-right: 5%;
}

nav svg {
  float: left;
}

.sideMenu {
  width: 244px;
  padding: 8px;
  background-color: var(--navBarColor);
  transition: left 0.3s ease;
}

.sideMenuButton {
  border: 1px solid var(--primary-figma-color);
  border-radius: 5px;
  padding: 10px;
  text-align: left;
  font-size: 14px;
  transition: all 0.25s ease;
  -webkit-transition: all 0.25s ease;
  -moz-transition: all 0.25s ease;
  -o-transition: all 0.25s ease;
}

.navPrompt:hover,
.whatsappcontainer:hover,
.sideMenuButton:hover {
  cursor: pointer;
  background-color: hsla(240, 3%, 13%, 0.941);
}

.sideMenuButton span {
  font-size: 20px;
  font-weight: 500;
  padding-left: 6px;
  padding-right: 10px;
}

.chatBox {
  flex: 1;
  position: relative;
  color: var(--textOverPrimaryColor);
  font-size: 18px;
  height: 30%;
  width: 100%;
  margin-top: 2vh;
}
  .relatedButton {
    background-color: #ffffff;
    display: inline;
    padding: 9px;
    font-size: 0.7em;
    box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
    font-weight: 500;
    margin: 31px;
    max-width: 102px;
    position: relative;
    bottom: 11px;
    border-radius: 10px;
}




.showRelatedButton{
  background-color: #ffffff;
  color: #000000;
  display: inline;
  top:2px;
  padding: 9px;
  font-size: 0.7em;
  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
  font-weight: 500;
  max-width: 102px;
  position: relative;
  bottom: 11px;
  border-radius: 10px;
}

.relatedSection {
  flex: 1;
  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
  position: relative;
  bottom: 12vh;
  position: relative;
  color: var(--textOverPrimaryColor);
  font-size: 18px;


}

#introsection::before,
#introsection::after {
  float: left;
  height: 10px;
  color: var(--textDarkToneColor);
}

#introsection {
  text-align: left;
  color: var(--textDarkToneColor);
  padding: 20px;
  margin-left: 2vw;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;

}

#introsection h1 {
  padding-top: 20px;
  line-height: 33px;
}

#introsection h2 {
  font-size: 16px;
  font-weight: 400;
}

#introsection ul {
  list-style-type: square;
}

#introsection pre {
  margin: 0;
  display: inline;
}

.chatLogWrapper {
  height: 65vh;

  overflow-y: auto;
}

.relatedQuestionsWrapper {
  height: 72vh;
  overflow-y: auto;
}

.relatedQuestionPresent {
  height: 72vh;
  overflow-y: auto;
}




.inputbackground {
  background-color: #4652b0;
}

/* adding custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--primary-bg-color);
}

::-webkit-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: var(--primary-figma-color);
}

::-moz-scrollbar {
  width: 8px;
  background-color: #4652b0;
}

::-moz-scrollbar-thumb {
  background-clip: content-box;
  border-color: transparent;
  border-style: solid;
  border-width: 1px 2px;
  background-color: rgb(229, 10, 10);
}

.dropdown {
  padding: 10px;
  background-color: antiquewhite;
  height: 35px;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 50px;
  width: 150px;
  border-width: 0;
  border-radius: 10px;
}

.formtext {
  margin-right: 10px;
}

.chatPrompt {
  padding-top: 6px;
  padding-bottom: 1px;
min-width: 53vw;
}
.relatedQuestionsContainer{
height: fit-content;
min-width: 84vw;}

.questions{
  background-color: #ffffff00;
}
.relatedQuestionsWrapper {
  max-height: 25vh;
  height: fit-content;
  overflow-y: auto;
  background: var(--hamburgerAndSentButton);
  color: #000000;
  width: 92%;
  border-radius: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}
.chatPromptWrapper {
  background: var(--hamburgerAndSentButton);
  color: white;
  font-weight: 700;
  width: 92%;
  height: min-content;
  border-radius: 10px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;

  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);

  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
}
.relatedHeading{
font-size: 1.5em;
font-weight: 600;
border-style: solid;
border-width: 0px 0px 4px 0px;
color: white;
}
.questionRelated{
color: #141414;
background: white;
bottom: 10px;
position: relative;
font-size: .7em;
border-radius: 5px;
padding: 14px;
box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.userSVG {
  transform: scale(0.7);
  background: white;
  border-radius: 10px;
  margin-top: 13px;
}

.botMessageMainContainer {
  width: 99%;
  border-radius: 10px;
  margin: auto;
  background-color: var(--primary-bg-color);
  position: relative;
}

.botMessageWrapper {
  max-width: 800px;
  color: var(--textDarkToneColor);
  margin: auto;
  padding-left: 12px;
  padding-top: 0px;
  padding-right: 20px;
  padding-bottom: 60px;
  line-height: 25px;
  text-align: left;
  display: flex;
  justify-content: left;
  /* align-items: center; */
  column-gap: 25px;
  font-size: 16px;
  line-height: 25px;

}

.stop-messgage {
  position: absolute;
  bottom: 10px;
  right: 100px;
  width: fit-content;
  padding: 10px 15px;
  border-radius: 10%;
  border: 1px solid var(--primary-figma-color);
  background-color: var(--primary-figma-color);
  color: var(--textOverPrimaryColor);
  cursor: pointer;
}

.stop-messgage:hover {
  background-color: rgb(64, 65, 79);
  /* border: none; */
}

.error {
  background-color: #ef444482;
  text-align: center;
  margin-top: 20px;
  padding-top: 30px;
  padding-bottom: 30px;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 10px;
}

.errorMessage {
  color: #ef4444;
  font-size: 16px;
  line-height: 24px;
}

.openaiSVG {
  transform: scale(0.5);
}

#avatar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}

#userphoto {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 4px;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: contain;
  object-position: center;
}




.inputPromptWrapper {
  margin-top: 30px;
  left: 50%;
  right: 50%;
  bottom: 0;
  position: absolute;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 200px;
  height: 55px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
  display: flex;

}



.detailWrapper {
  margin-top: 30px;
  left: 50%;
  right: 50%;
  bottom: 0;
  position: relative;
  transform: translate(-50%, -50%);
  width: 80%;
  max-height: 200px;
  height: 55px;
  border-radius: 5px;
  box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
  display: flex;
}

.inputPrompttTextarea {
  padding-left: 20px;
  padding-right: 60px;

  width: 100%;
  border-radius: 10px;
  flex: 1;

  color: var(--primary-figma-color);

  font-size: 16px;
  outline: none;
  border: none;
  overflow-y: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.phoneTextarea {
  padding-left: 20px;
  padding-right: 60px;
  width: 100%;
  border-radius: 10px;
  flex: 1;
  border-color: var(--navBarColor);
  background-color: var(--primary-bg-color);
  color: var(--primary-figma-color);
  font-size: 16px;
  outline: none;
  border-width: 1px;
  overflow-y: hidden;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

form button {
  background: #212023;
  border-radius: 50%;
  border: none;
  position: relative;
  top: 9%;
  right: 10px;
  width: 101px;
  height: 45px;
}

.sentbutton {
  background: #212023;
  border-radius: 50%;
  border: none;
  top: 9%;
  right: -8px;
  width: 45px;
  height: 45px;


}
   

  .sentbutton_ECD:active{
      transform: scale(1.1);
      background-color:var(--BgColorForActiveTime);
      color: var(--TextColorforActiveTime);
      font-weight: bold;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.6); 
    }




@media (hover: hover) {
  button:hover {
    cursor: pointer;
    background: var(--hamburgerAndSentButton);
    border-radius: 50%;
  }
}

.loginContainer {
  background: var(--primary-bg-color);
  width: 100%;
  height: 100%;
  font-family: var(--text-font);


}

.loginContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--primary-bg-color);
  height: 100%;
  background: #146b63;

}

.NumberContainerContent {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--textDarkToneColor);
  height: 100%;
  background: var(--primary-bg-color);
}

.loginContainerContent svg {
  padding-bottom: 30px;
}

.loginContainer h1 {
  font-size: 30px;
  margin: 0;
}

.loginContainer p {
  font-size: 18px;
  line-height: 5px;
}

.loginButtonWrapper {
  display: flex;
  align-items: center;
  column-gap: 15px;
  margin-top: 20px;
}

#loginButton {
  padding: 10px 25px;
  margin-top: 10px;
  border-radius: 7px;
  color: var(--textOverPrimaryColor);
  background: var(--primary-figma-color);
  border: none;
  font-size: 16px;
  cursor: pointer;
}

#submitbutton {
  padding: 10px 25px;
  border-radius: 7px;
  color: var(--textOverPrimaryColor);
  background: var(--primary-figma-color);
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.loginimage {
  height: 400px;
  position: relative;
  left: 4px;
  display: block;

}

#loginButton:hover {
  background-color: rgb(26, 127, 100);
}

@media screen and (min-width: 2559px) {
  .loginContainer h1 {
    font-size: 40px;
  }
  .avatar-button2 {
    margin-left: 63px;
  }

  .right-side-container{
    margin-right: 60px;
  }
  .avatar-button3{
    margin-left: 20px;
  }
  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 17vw;}
 
  .chatPrompt {
    padding-top: 6px;
    padding-bottom: 1px;
    min-width: 22vw;
  }

  .relatedSection {
    bottom:-11vh; 
  }

  .chatLogWrapper {
    height: 65vh;
    overflow-y: auto;
  
} 
    .avatar-wrapper {
    background-color: #ffffff;
    padding: 20px;
    margin-top: 20px;
    height: 120px;
  
  }
  .avatar-logo2 {
    background-color: #ffffff;
    width: 101px;
    justify-content: left;
}
.hitext {
  font-size: 20px;
  padding-top: 16px;
  font-weight: 300;
  text-align: left;
}
.hitext2 {
  font-size: 30px;
  font-weight: 600;
  text-align: left;
}
#introsection {
  text-align: left;
  color: var(--textDarkToneColor);
  padding-left: 65px;
}
  .loginContainer p {
    font-size: 25px;
  }
  .right-side-container {
    transform: scale(1.02);
    padding-right: 20px;
  }

  #loginButton {
    font-size: 22px;
  }

  .loginimage {
    height: 350px;



  }


  /* Your styles for devices above 2560px */
}
@media screen and (min-width: 1441px) and (max-width: 2558px) {
  .avatar-button2 {
    margin-left: 63px;
  }

  .right-side-container{
    margin-right: 60px;
  }
  .avatar-button3{
    margin-left: 20px;
  }
  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 17vw;}
 
  .chatPrompt {
    padding-top: 6px;
    padding-bottom: 1px;
    min-width: 30vw;
  }
  .relatedSection {
    bottom:-4vh; 
  } 
  /* Your styles for devices between 1441px and 2560px */
}


@media screen and (min-width: 1025px) and (max-width: 1440px) {
  /* Your styles for devices between 1025px and 1440px */
  .avatar-button2 {
    margin-left: 63px;
  }

  .right-side-container{
    margin-right: 60px;
  }
  .avatar-button3{
    margin-left: 20px;
  }
  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 17vw;}
 
  .chatPrompt {
    padding-top: 6px;
    padding-bottom: 1px;
    min-width: 45vw;

  }
  .relatedSection {
    bottom:4vh; 
  } 
}

@media screen and (min-width: 768px) and (max-width: 1024px) {

  .avatar-button2 {
    margin-left: 63px;
  }

  .right-side-container{
    margin-right: 60px;
  }
  .avatar-button3{
    margin-left: 20px;
  }
  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 17vw;}
 
  .chatPrompt {
    padding-top: 6px;
    padding-bottom: 1px;
    min-width: 57vw;
  }
  .relatedSection {
    bottom:4vh; 
  } 
  /* Your styles for tablet sizes */
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  /* Your styles for tablet sizes */
}
@media screen and (min-width: 768px) {
  .inputPromptWrapper {
    position: absolute;
  }


}
@media screen and (max-width: 1536px) {
 
}
@media screen and (max-width: 1024px) {
  .App {
    display: block;
  }
  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 50vw;}
 
  .chatPrompt {
    padding-top: 6px;
    padding-bottom: 1px;
 
  }

  .relatedSection {

    bottom:-9vh; 
  }
  .hitext {
    font-size: 16px;
    padding-top: 25px;
   font-weight: 300;
    text-align: left;
  }
  
  .hitext2 {
    font-size: 18px;
   font-weight: 600;
    text-align: left;
  }
  #dentalailogo {
    transform: scale(0.6);
  }

  #whatsapplogo {
    transform: scale(0.8);
  }
  .navPrompt {
    transform: scale(0.67);
  }
  .avatar-wrapper {
    background-color: #ffff;
    width: 98%;
  }

  header {
    display: flex;
    height: 50px;
    align-items: center;
    background: var(--primary-figma-color);
    border-bottom: 10px solid hsl(0deg 0% 100% / 20%);
    padding: 4px 0 0 12px;

  }

  nav {
    display: flex;
  }

  .sideMenu {
    display: flex;
  }

  .chatBox {
    position: static;
  }
  .relatedSection {
    position: relative;
  }

  .chatPromptWrapper {
    padding: 10px;
  }

  .botMessageWrapper {
    padding-top: 25px;
    padding-bottom: 40px;
  }

  .stopmessage1 {
    stroke: #000000;
    stroke-width: 10px;

  }

  .stop-messgage {
    right: 5px;
    font-size: 13px;
    padding: 5px 15px;
  }

  .userSVG {
    transform: scale(0.35);
  }

  .openaiSVG {
    transform: scale(0.4);
  }

  #avatar {
    width: 30px;
    height: 30px;
  }

  #userphoto {
    margin-top: 4px;

    width: 100px;
    height: 100px;

  }

  #introsection {
    color: var(--textDarkToneColor);
    padding: 4px 35px 35px px;

    font-size: 14px;
  }

  #introsection h1,
  pre {
    font-size: 18px;
    white-space: pre-wrap;
  }

  .relatedQuestionsContainer{
    height: fit-content;
    min-width: 50vw;}
    

  #introsection h2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 430px) {
  .number123{
    transform: scale(0.7);
  }
  #loginButton {
    transform: scale(0.7);
}
.loginContainer h1 {
  font-size: 20px;
  margin: 0;
}
.chatBox {
    flex: 1 1;
    background-color: var(--primary-bg-color);
    position: relative;
    color: var(--textOverPrimaryColor);
    font-size: 15px;
}
.relatedSection {

bottom: 0vh;
  flex: 1 1;
  background-color: var(--primary-bg-color);
  position: relative;
  color: var(--textOverPrimaryColor);
  font-size: 15px;
}

.hitext {
  font-size: 14px;
  padding-top: 25px;
 font-weight: 300;
  text-align: left;
}

.hitext2 {
  font-size: 16px;
 font-weight: 600;
  text-align: left;
}

  .avatar-logo2{
    background-color: #ffffff;
    width: 65px;
    justify-content: left;
  }
  .avatar-button1 {
    margin-right: 5px;
  }
  .avatar-wrapper {
    background-color: #ffffff;
    height: 10vh;
  }

  #dentalailogo {
    transform: scale(0.5);
  }

  #whatsapplogo {
    transform: scale(0.7);
  }
  .navPrompt {
    transform: scale(0.57);
  }

  .loginimage {
    width: 96%;
    height: auto;
  }



  .whatsappcontainer {
    color: var(--primary-bg-color);
    border-radius: 10px;

    text-align: left;
  }



  .vertical_spacing {

    width: 15px;
    height: 10px;
  }

  .inputPromptWrapper {
    margin-top: 30px;
    left: 50%;
    right: 50%;
    top: 90vh;
    bottom: 0;
    position: absolute;
    transform: translate(-50%, -50%);
    width: 80%;
    max-height: 200px;
    height: 45px;
    border-radius: 5px;
    box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
    display: flex;

  }

  .chatLogWrapper {
    height: 65vh;
    overflow-y: auto;

  }
  .relatedQuestionsWrapper {
    max-height: 30vh;
    overflow-y: auto;
    height: fit-content;
  }

  .relatedQuestionPresent {
    height: 32vh;
    overflow-y: auto;
  }

  .botMessageWrapper {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .chatPromptWrapper {
    column-gap: 0px;
    margin-top: 10px;
    margin-bottom: 1px
  }

  .loginimage {
    width: 96%;
    height: auto;
  }

  #introsection h1 {
    font-size: 25px;
    white-space: pre-wrap;
    padding-top: 10px;
    margin: 0;
  }
  pre {
    font-size: 14px;
    white-space: pre-wrap;
    padding-top: 5px;
    margin: 0;
  }
  #introsection {
    background: var(--primary-bg-color);
    color: var(--textDarkToneColor);
    padding: 1% 3% 5% 4%;
    height: auto;
    font-size: 14px;
  }

  .userSVG {
    transform: scale(0.35);
  }


}
@media screen and (max-width:275px){
  .number123{
    transform: scale(0.4);
  }
  
} 
@media screen and (max-height: 900px) {
  .chatLogWrapper {
    max-height: 65vh;
    height: 77vh;
    overflow-y: auto;
  }
  .relatedQuestionsWrapper {
    max-height: 35vh;
    height: fit-content;
    overflow-y: auto;
  }
  .relatedQuestionPresent {
    height: 32vh;
    overflow-y: auto;
  }
  .relatedSection{
    bottom: 3vh;
  }

}
@media screen and (max-height: 800px) {
  .chatLogWrapper {
    height: 65vh;
    overflow-y: auto;
  }
  .relatedQuestionsWrapper {
    height: fit-content;
    max-height: 30vh;
    overflow-y: auto;
  } 
  .relatedQuestionPresent {
    height: 32vh;
    overflow-y: auto;
  }
}
@media screen and (max-height: 700px) {
  .chatLogWrapper {
    height: 65vh;
    overflow-y: auto;
  }

  .relatedQuestionsWrapper {
    height: fit-content;
    max-height: 30vh;
    overflow-y: auto;
  }   
  .relatedQuestionPresent {
    height: 32vh;
    overflow-y: auto;
  }
  .relatedSection {
    bottom:1vh; 
  }  

  }
@media screen and (max-height: 600px) {
  .chatLogWrapper {
    height: 65vh;
    overflow-y: auto;
  }
  .relatedQuestionsWrapper {
    height: fit-content;
    max-height: 30vh;
    overflow-y: auto;
  } 

  .relatedQuestionPresent {
    height: 32vh;
    overflow-y: auto;
  }
  .relatedSection {
    bottom:1vh; 
  } 

}
  @media screen and (max-height: 529px) {
    .chatLogWrapper {
      height: 65vh;
      overflow-y: auto;
    }
    .relatedQuestionsWrapper {
      height: fit-content;
      max-height: 30vh;
      overflow-y: auto;
    } 
    .relatedQuestionsWrapper {
      height: 32vh;
      overflow-y: auto;
      background: var(--hamburgerAndSentButton);
      color: #000000;
      width: 92%;
      height: min-content;
      border-radius: 10px;
      max-width: 800px;
      margin-left: auto;
      margin-right: auto;
      margin-top: 10px;
      margin-bottom: 10px;
      padding: 10px;
      box-shadow: 0 0 2px 0 rgba(225, 9, 9, 0);
      text-align: left;
      display: flex;
      justify-content: left;
      /* align-items: center; */
      column-gap: 25px;
    }
    .relatedSection {
      bottom:1vh; 
    } 

  }

